Vgr.systemPermissions = {
	// master
	MasterViewClientData: { Id: 1, Type: Vgr.enumerations.systemPermissions.types.Master },
	MasterCreateEditClientData: { Id: 5, Type: Vgr.enumerations.systemPermissions.types.Master },
	MasterViewFeatureToggles: { Id: 915, Type: Vgr.enumerations.systemPermissions.types.Master },

	// organization
	EditOrganizationPermissions: { Id: 8, Type: Vgr.enumerations.systemPermissions.types.Organization },
	EditOrganization: { Id: 38, Type: Vgr.enumerations.systemPermissions.types.Organization },
	EditOrganizationAdditionalProperties: { Id: 526, Type: Vgr.enumerations.systemPermissions.types.Organization },
	BiModuleReportEdit: { Id: 506, Type: Vgr.enumerations.systemPermissions.types.Organization },
	BiModuleReportDelete: { Id: 507, Type: Vgr.enumerations.systemPermissions.types.Organization },

	// organization unit
	CreateEditDeleteArea: { Id: 9, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },
	CreateEditDeleteResidueRecipient: { Id: 10, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },
	EditUnit: { Id: 11, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },
	EditUnitPermissions: { Id: 12, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },

	ViewDisposal: { Id: 13, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit, IsView: true },
	CreateEditDeleteDisposalUnfinished: { Id: 14, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },
	EditDeleteDisposalFinished: { Id: 15, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },
	ResidueDisposalCreateEditDeletePastMonths: {
		Id: 37,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit
	},

	ViewGathering: { Id: 18, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit, IsView: true },
	CreateEditDeleteGathering: { Id: 19, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },

	UseNotDefaultMeasureUnit: { Id: 21, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },
	UseNotDefaultRecipient: { Id: 22, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },
	UseNotDefaultDisposalType: { Id: 23, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },

	//-------------------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------------------
	//Suppliers
	SupplierVehicleAndDriver: { Id: 531, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierPreferences: { Id: 533, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierOrganizationMaster: { Id: 525, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierNotOwnedEdit: { Id: 539, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierEdit: { Id: 541, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierOrganizationDocument: { Id: 519, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierDocument: { Id: 542, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierOrganizationAuditLevel1: { Id: 534, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierAuditLevel1: { Id: 543, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierOrganizationAuditLevel2: { Id: 535, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierAuditLevel2: { Id: 544, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierOrganizationAuditLevel3: { Id: 536, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierAuditLevel3: { Id: 545, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierOrganizationActionPlan: { Id: 39, Type: Vgr.enumerations.systemPermissions.types.Organization },
	SupplierActionPlan: { Id: 546, Type: Vgr.enumerations.systemPermissions.types.Organization },
	OrganizationChart: { Id: 550, Type: Vgr.enumerations.systemPermissions.types.Organization },
	//-------------------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------------------

	ViewMarket: { Id: 34, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit, IsView: true },

	GoalView: { Id: 41, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit, IsView: true },
	GoalEdit: { Id: 42, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },

	UnlockDisposalGathering: { Id: 301, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },
	CrudDisposalModels: { Id: 302, Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit },
	ViewResiduesGeneratedFromAllAreas: {
		Id: 303,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit,
		IsView: true
	},
	ViewResiduesStoredFromAllAreas: {
		Id: 304,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit,
		IsView: true
	},
	CreateAndEditResiduesGenerationInAllAreas: {
		Id: 305,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit
	},
	CreateAndEditResiduesGenerationInUserAreas: {
		Id: 306,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit
	},
	ViewResiduesGeneratedFromUsersAreas: {
		Id: 313,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit
	},
	ViewResiduesStoredFromUsersAreas: {
		Id: 314,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit
	},
	EditDisposalCosts: {
		Id: 315,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit
	},
	ViewInternalTransfer: {
		Id: 316,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit
	},

	DestinatorDisposalManifestDestinatorView: {
		Id: 601,
		Type: Vgr.enumerations.systemPermissions.types.OrganizationUnit,
		IsView: true
	},
	GenerateDmr: {
		Id: 700,
		Type: Vgr.enumerations.systemPermissions.types.Organization,
		IsView: true
	},
	MaturityView: {
		Id: 914,
		Type: Vgr.enumerations.systemPermissions.types.Organization,
		IsView: true
	}
};
